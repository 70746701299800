import { render, staticRenderFns } from "./LayoutCol1.vue?vue&type=template&id=982945be&"
import script from "./LayoutCol1.vue?vue&type=script&lang=ts&"
export * from "./LayoutCol1.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports