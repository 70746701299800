
import { Component, Emit, Mixins } from 'vue-property-decorator';
import store from '@/store';
import ViewPage from '@/mixins/Views';
import LayoutMapContainer from '@/components/layout/LayoutMapContainer.vue';
import CouponData from '@/mixins/CouponData2022_2';

@Component({
  components: {
    LayoutMapContainer,
  },
})
export default class CouponPage extends Mixins(ViewPage) {
  private name = 'coupon';
  private data!: ApiCoupon;
  get userName() {
    return this.$store.getters['auth/name'];
  }
  get textPeriod() {
    const startDate: Date = new Date(this.data.startDate);
    const finishDate: Date = new Date(this.data.finishDate);
    const startText: string = `${startDate.getFullYear()}年${startDate.getMonth() + 1}月${startDate.getDate()}日`;
    let finishText: string = `${finishDate.getFullYear()}年${finishDate.getMonth() + 1}月${finishDate.getDate()}日`;
    if (startDate.getFullYear() === finishDate.getFullYear()) {
      finishText = `${finishDate.getMonth() + 1}月${finishDate.getDate()}日`;
    }
    return `（有効期間：${startText}から${finishText}まで）`;
  }
  private beforeRouteEnter(to: any, from: any, next: any) {
    const today: Date = new Date();
    const res = CouponData.filter((value: ApiCoupon) => {
      const startDate: Date = new Date(value.startDate);
      if (
        today >= new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() - process.env.VUE_APP_COUPON_START_BUFFER,
          startDate.getHours(),
          startDate.getMinutes(),
        ) &&
        today <= new Date(value.finishDate) &&
        value.slug === to.params.name
      ) {
        return value;
      }
    });
    if (res.length >= 1) {
      next();
    } else {
      next('/');
    }
  }
  @Emit()
  private filterCouponData(now: Date, slug: string): ApiCoupon[] {
    const res = CouponData.filter((value: ApiCoupon) => {
      const startDate: Date = new Date(value.startDate);
      if (
        now >= new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() - process.env.VUE_APP_COUPON_START_BUFFER,
          startDate.getHours(),
          startDate.getMinutes(),
        ) &&
        now <= new Date(value.finishDate) &&
        value.slug === slug
      ) {
        return value;
      }
    });
    return res;
  }
  private created() {
    const today: Date = new Date();
    const res = this.filterCouponData(today, this.$route.params.name);
    this.data = res[0];
    store.commit('loaded', true);
  }
}
