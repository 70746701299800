
import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/store';
import LayoutCol1 from '@/components/layout/LayoutCol1.vue';
@Component({
  components: {
    LayoutCol1,
  },
})
export default class LayoutMapContainer extends Vue {
  private name = 'LayoutMapContainer';
  get lang(): string {
    return store.getters['auth/lang'];
  }
  get closeBtnText(): string {
    if (this.lang === 'ja') {
      return '閉じる';
    } else {
      return 'CLOSE';
    }
  }
}
