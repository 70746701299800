const data: ApiCoupon[] = [
  {
    name: '三井ガーデンホテル汐留イタリア街',
    slug: 'mitsui-garden-hotel-shiodome-italia-gai',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f1.jpg',
      sp: '/assets/imgs/coupon/coupon2022f1-sp.jpg',
    },
    startDate: '2022-06-13T00:00:00',
    finishDate: '2022-11-30T23:59:59',
  },
  {
    name: '三井ガーデンホテルプラナ東京ベイ',
    slug: 'mitsui-garden-hotel-prana-tokyo-bay',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f1.jpg',
      sp: '/assets/imgs/coupon/coupon2022f1-sp.jpg',
    },
    startDate: '2022-06-13T00:00:00',
    finishDate: '2022-11-30T23:59:59',
  },
  {
    name: '三井ガーデンホテル福岡祇園',
    slug: 'mitsui-garden-hotel-fukuoka-gion',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f1.jpg',
      sp: '/assets/imgs/coupon/coupon2022f1-sp.jpg',
    },
    startDate: '2022-06-13T00:00:00',
    finishDate: '2022-11-30T23:59:59',
  },
  {
    name: '三井ガーデンホテル京都駅前',
    slug: 'mitsui-garden-hotel-kyoto-station',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f1.jpg',
      sp: '/assets/imgs/coupon/coupon2022f1-sp.jpg',
    },
    startDate: '2022-06-13T00:00:00',
    finishDate: '2022-11-30T23:59:59',
  },
  {
    name: '三井ガーデンホテル銀座五丁目',
    slug: 'mitsui-garden-hotel-ginza-gochome',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f1.jpg',
      sp: '/assets/imgs/coupon/coupon2022f1-sp.jpg',
    },
    startDate: '2022-06-13T00:00:00',
    finishDate: '2022-11-30T23:59:59',
  },
  {
    name: 'ホテル ザ セレスティン東京芝',
    slug: 'hotel-the-celestine-tokyo-shiba',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f1.jpg',
      sp: '/assets/imgs/coupon/coupon2022f1-sp.jpg',
    },
    startDate: '2022-06-13T00:00:00',
    finishDate: '2022-11-30T23:59:59',
  },


  {
    name: 'ホテル ザ セレスティン銀座',
    slug: 'hotel-the-celestine-ginza',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f2.jpg',
      sp: '/assets/imgs/coupon/coupon2022f2-sp.jpg',
    },
    startDate: '2022-10-01T00:00:00',
    finishDate: '2023-03-26T23:59:59',
  },
  {
    name: 'ホテル ザ セレスティン京都祇園',
    slug: 'hotel-the-celestine-kyoto-gion',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f2.jpg',
      sp: '/assets/imgs/coupon/coupon2022f2-sp.jpg',
    },
    startDate: '2022-10-01T00:00:00',
    finishDate: '2023-03-26T23:59:59',
  },
  {
    name: '三井ガーデンホテル神宮外苑の杜プレミア',
    slug: 'mitsui-garden-hotel-jigugaien-tokyo-premier-2',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f2.jpg',
      sp: '/assets/imgs/coupon/coupon2022f2-sp.jpg',
    },
    startDate: '2022-10-01T00:00:00',
    finishDate: '2023-03-26T23:59:59',
  },
  {
    name: '三井ガーデンホテル六本木プレミア',
    slug: 'mitsui-garden-hotel-roppongi-tokyo-premier',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f2.jpg',
      sp: '/assets/imgs/coupon/coupon2022f2-sp.jpg',
    },
    startDate: '2022-10-01T00:00:00',
    finishDate: '2023-03-26T23:59:59',
  },
  {
    name: '三井ガーデンホテル札幌ウエスト',
    slug: 'mitsui-garden-hotel-sapporo-west',
    images: {
      pc: '/assets/imgs/coupon/coupon2022f2.jpg',
      sp: '/assets/imgs/coupon/coupon2022f2-sp.jpg',
    },
    startDate: '2022-10-01T00:00:00',
    finishDate: '2023-03-26T23:59:59',
  },
];
export default data;
