
import { Component, Mixins, Emit } from 'vue-property-decorator';
import store from '@/store';
import ViewPage from '@/mixins/Views';
import LayoutMapContainer from '@/components/layout/LayoutMapContainer.vue';
import CouponData from '@/mixins/CouponData2022_2';

@Component({
  components: {
    LayoutMapContainer,
  },
})
export default class CouponTop extends Mixins(ViewPage) {
  private name = 'coupon_top';
  private selectValue: string = '';
  private selectOptions!: ApiCoupon[];
  get userName() {
    return this.$store.getters['auth/name'];
  }
  @Emit()
  private submitForm() {
    this.$router.push({ path: `/coupon2022_2/${this.selectValue}` });
  }
  @Emit()
  private clickButton() {
    this.submitForm();
  }
  private created() {
    const today: Date = new Date();
    this.selectOptions = CouponData.filter((value: ApiCoupon) => {
      const startDate: Date = new Date(value.startDate);
      if (
        today >= new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() - process.env.VUE_APP_COUPON_START_BUFFER,
          startDate.getHours(),
          startDate.getMinutes(),
        ) &&
        today <= new Date(value.finishDate)
      ) {
        return value;
      }
    });

  }
  private async mounted() {
    store.commit('loaded', true);
  }
}
